/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, {
  Source,
  Layer,
  WebMercatorViewport,
  NavigationControl,
  Marker,
} from 'react-map-gl';
import bbox from '@turf/bbox';
import centroid from '@turf/centroid';
import { multiLineString } from '@turf/helpers';
import _ from 'underscore';
import ReactResizeDetector from 'react-resize-detector';
import AtlasGeneric from './AtlasGeneric.jsx';
import AtlasTooltip from './AtlasTooltip/AtlasTooltip.jsx';
import AtlasInset from './AtlasInset/AtlasInset.jsx';
import AtlasMarkerDraggable from './AtlasMarkerDraggable/AtlasMarkerDraggable.jsx';
import {
  countries,
  chCountries,
  chCountriesBorder,
  countriesHighlight,
  countriesMask,
  areasHighlight,
  areas,
  urbanAreas,
  urbanAreasCenter,
  idp,
  idpHover,
  hhg,
  hhgHover,
  rfgCA as rfg,
  rfgHoverCA as rfgHover,
  areasBorder,
  areasLabels,
  bags,
    rfm,
  areasGroup,
  areasFamine,
  areasGroupFamine,
} from './interactive-layers';
import './AtlasNew.scss';

class Atlas extends AtlasGeneric {
  getFeatureFromEvent = event => {
    const { features } = event;
    return _.find(
      features,
      f => f.layer.id === (this.state.countryZoomLevel ? 'areas' : 'countries') || 'urbanAreas'
    );
  };

  onHover = event => {
    if (this.props.show_area_popups && this.state.showTooltip) {
      const x = event.srcEvent.clientX;
      const y = event.srcEvent.clientY;
      const feature = this.getFeatureFromEvent(event);
      let highlightedFeature = null;
      if (feature) {
        let { properties } = feature;
        if (this.props.group) {
          let period = 'current';
          if (this.props.activePeriod === 'A') period = 'projected2';
          if (this.props.activePeriod === 'P') period = 'projected';

          properties = _.omit(feature.properties, (value, key) => key.match(/phase\d_.*/));
          Object.keys(feature.properties).forEach(key => {
            if (key.match(/phase\d_.*/) && key.match(period)) {
              const newKey = key.replace(/.*?_(phase\d.*)/, '$1');
              properties[newKey] = feature.properties[key];
            } else if (key === `${period}_overall_phase`) {
              properties.overall_phase = feature.properties[key];
            }
          });
        }
        feature.properties = properties;
        highlightedFeature = {
          ...feature.properties,
          layer: feature.layer.id,
          id: feature.id,
          sourceLayer: feature.layer['source-layer'],
        };
      }
      this.props.setHighlightedFeature(highlightedFeature);
      if (feature) {
        const tooltip = <AtlasTooltip key="mapTooltip" feature={feature} pos={{ x, y }} />;
        this.setState({ tooltip });
      } else {
        this.removeTooltip();
      }
    }
  };

  // onClick = event => {
  //   const feature = this.getFeatureFromEvent(event);
  //   if (feature) {
  //     const { stickyPopup } = this.state;
  //     const origin = centroid(feature.geometry).geometry.coordinates;
  //     const [lng, lat] = event.lngLat;
  //     stickyPopup[feature.id] = {
  //       feature,
  //       lng,
  //       lat,
  //       origin,
  //       top: origin[1] < lat,
  //       left: origin[0] > lng,
  //     };
  //     this.removeTooltip();
  //     this.setState(stickyPopup);
  //   }
  // };

  componentDidMount = () => {
    const { saveMapRef, activePeriod } = this.props;
    saveMapRef({
      type: `main-${activePeriod}`,
      position: activePeriod === 'A' ? '2nd-right' : activePeriod === 'P' ? 'right' : 'left',
      map: this.map.current.getMap(),
      period: activePeriod,
    });
    // console.log('atlas-props-geojson', this.props.geojson);
    if (this.props.geojson) {
      const [minLng, minLat, maxLng, maxLat] = bbox(this.props.geojson);
      this.zoomToBounds([minLng, minLat, maxLng, maxLat]);
      this.setState({
        countryZoomLevel: true,
      });
    }
  };

  componentWillUnmount = () => {
    const { deleteMapRef, activePeriod } = this.props;
    deleteMapRef({ type: `main-${activePeriod}` });
  };

  componentDidUpdate = prevProps => {
    let newStyle = null;
    Object.keys(this.props.styleOverrides).forEach(key => {
      const style = this.props.styleOverrides[key];
      if (
        !prevProps.styleOverrides[key] ||
        style[0].value !== prevProps.styleOverrides[key][0].value
      ) {
        newStyle = style;
      }
    });
    if (newStyle) {
      this.updateStyle(newStyle);
    }
    if (prevProps.highlightedFeature !== this.props.highlightedFeature) {
      if (prevProps.highlightedFeature) {
        this.map.current.getMap().setFeatureState(
          {
            source: this.props.group ? 'ipc' : 'geojson-source',
            sourceLayer: prevProps.highlightedFeature.sourceLayer,
            id: prevProps.highlightedFeature.id,
          },
          { hover: false }
        );
        if (
          prevProps.highlightedFeature.view_level &&
          prevProps.highlightedFeature.view_level.toLowerCase() === 'group' &&
          this.props.geojson
        ) {
          if (prevProps.highlightedFeature.group) {
            const { title } = JSON.parse(prevProps.highlightedFeature.group);
            this.props.geojson.features.forEach(feature => {
              if (feature.properties.group && feature.properties.group.title === title) {
                this.map.current.getMap().setFeatureState(
                  {
                    source: this.props.group ? 'ipc' : 'geojson-source',
                    sourceLayer: prevProps.highlightedFeature.sourceLayer,
                    id: feature.id,
                  },
                  { hover: false }
                );
              }
            });
          }
        }
      }
      if (this.props.highlightedFeature) {
        this.map.current.getMap().setFeatureState(
          {
            source: this.props.group ? 'ipc' : 'geojson-source',
            sourceLayer: this.props.highlightedFeature.sourceLayer,
            id: this.props.highlightedFeature.id,
          },
          { hover: true }
        );
        if (
          this.props.highlightedFeature.view_level &&
          this.props.highlightedFeature.view_level.toLowerCase() === 'group' &&
          this.props.geojson
        ) {
          if (this.props.highlightedFeature.group) {
            const { title } = JSON.parse(this.props.highlightedFeature.group);
            this.props.geojson.features.forEach(feature => {
              if (feature.properties.group && feature.properties.group.title === title) {
                this.map.current.getMap().setFeatureState(
                  {
                    source: this.props.group ? 'ipc' : 'geojson-source',
                    sourceLayer: this.props.highlightedFeature.sourceLayer,
                    id: feature.id,
                  },
                  { hover: true }
                );
              }
            });
          }
        }

        if (this.props.highlightedFeature.aar_id) {
          const feature = this.map.current.getMap().queryRenderedFeatures(null, {
            layers: ['areas'],
            filter: ['==', ['get', 'aar_id'], this.props.highlightedFeature.aar_id],
          });
          if (feature.length) {
            const { properties } = feature[0];
            if (this.props.group) {
              Object.keys(properties).forEach(key => {
                let pd = 'current';
                if (this.props.activePeriod === 'P') pd = 'projected';
                else if (this.props.activePeriod === 'A') pd = 'projected2';
                const regex = new RegExp(`${pd}(_overall)?_phase(\\d)?((_pop)|(_per))?`, 'gi');
                if (key.match(regex)) {
                  const replace = new RegExp(`${pd}_`, 'gi');
                  const newKey = key.replace(replace, '');
                  properties[newKey] = properties[key];
                }
              });
            }
            const {
              geometry: { coordinates },
            } = centroid(feature[0]);
            const featurePos = this.map.current.getMap().project(coordinates);
            const containerPos = this.map.current.getMap().getContainer().getBoundingClientRect();
            const tooltip = (
              <AtlasTooltip
                key="mapTooltip"
                reduceTitleFontSize={!this.state.countryZoomLevel}
                feature={{ properties }}
                pos={{ x: featurePos.x + containerPos.x, y: featurePos.y + containerPos.y }}
              />
            );
            this.setState({ tooltip });
          }
        }
      } else {
        this.removeTooltip();
      }
    }
    if (prevProps.highlightedAreas !== this.props.highlightedAreas) {
      if (prevProps.highlightedAreas && prevProps.highlightedAreas.length) {
        const [prevFeature] = this.map.current.getMap().queryRenderedFeatures(null, {
          layers: ['areas'],
          filter: ['in', ['get', 'aar_id'], ...prevProps.highlightedAreas],
        });
        if (prevFeature && prevFeature.length) {
          this.map.current.getMap().setFeatureState(
            {
              source: this.props.group ? 'ipc' : 'geojson-source',
              sourceLayer: prevFeature.sourceLayer,
              id: prevFeature.id,
            },
            { hover: false }
          );
        }
      }
      if (this.props.highlightedAreas && this.props.highlightedAreas.length) {
        const features = this.map.current.getMap().queryRenderedFeatures(null, {
          layers: ['areas'],
          filter: ['in', ['get', 'aar_id'], ...this.props.highlightedAreas],
        });
        if (features && features.length) {
          const [feature] = features;
          const { properties } = feature;
          const {
            geometry: { coordinates },
          } = centroid(feature);
          const featurePos = this.map.current.getMap().project(coordinates);
          const containerPos = this.map.current.getMap().getContainer().getBoundingClientRect();
          const tooltip = (
            <AtlasTooltip
              key="mapTooltip"
              reduceTitleFontSize={!this.state.countryZoomLevel}
              feature={{ properties }}
              pos={{ x: featurePos.x + containerPos.x, y: featurePos.y + containerPos.y }}
            />
          );

          this.map.current.getMap().setFeatureState(
            {
              source: this.props.group ? 'ipc' : 'geojson-source',
              sourceLayer: feature.sourceLayer,
              id: feature.id,
            },
            { hover: true }
          );

          this.setState({ tooltip });
        }
      } else {
        this.removeTooltip();
      }
    }

    if (this.props.compareBounds && !prevProps.compareBounds) {
      const { width, height } = this.state.viewport;
      const vp = new WebMercatorViewport({ width, height }).fitBounds(this.props.compareBounds);
      this.setState({
        viewport: {
          ...this.state.viewport,
          ...vp,
        },
      });
    }
    if (this.props.geojson && !prevProps.geojson) {
      const [minLng, minLat, maxLng, maxLat] = bbox(this.props.geojson);
      this.zoomToBounds([minLng, minLat, maxLng, maxLat]);
      this.setState({
        countryZoomLevel: true,
      });
    }
    if (this.props.activePeriod !== prevProps.activePeriod) {
      const { saveMapRef, activePeriod } = this.props;
      saveMapRef({
        type: `main-${activePeriod}`,
        map: this.map.current.getMap(),
        period: activePeriod,
        position: activePeriod === 'A' ? '2nd-right' : activePeriod === 'P' ? 'right' : 'left',
      });
      // this.setState({
      //   bounds: this.map.current ? this.map.current.getMap().getBounds() : null,
      // });
    }
  };

  // get the data layer properties either for geojson (for a single analysis) or vector tiles
  getLayers = type => {
    const areaPhaseFilter = [
      'match',
      ['get', 'overall_phase'],
      [...this.props.activePhases],
      true,
      false,
    ];
    const areaConditionFilter = ['==', ['get', 'condition'], this.props.activeCondition];
    const layerData = {
      countries: { ...countries },
      chCountries: { ...chCountries },
      chCountriesBorder: { ...chCountriesBorder },
      countriesHighlight: { ...countriesHighlight },
      countriesMask: { ...countriesMask },
      areasHighlight: { ...areasHighlight },
      areas: { ...areas },
      areasGroup: { ...areasGroup },
      areasFamine: { ...areasFamine },
      areasGroupFamine: { ...areasGroupFamine },
      urbanAreas: { ...urbanAreas },
      urbanAreasCenter: { ...urbanAreasCenter },
      idp: { ...idp },
      idpHover: { ...idpHover },
      hhg: { ...hhg },
      hhgHover: { ...hhgHover },
      rfg: { ...rfg },
      rfgHover: { ...rfgHover },
      areasNegative: JSON.parse(JSON.stringify(areas)),
      areasBorder: { ...areasBorder },
      areasLabels: { ...areasLabels },
      bags: { ...bags },
      rfm: {...rfm}
    };

    layerData.areasNegative.id = 'areas-negative';
    layerData.areasNegative.paint['fill-opacity'] = 0;
    layerData.areasGroup.paint['fill-color'][layerData.areasGroup.paint['fill-color'].length - 1] =
      '#b0b0b0';

    const { geojson } = this.props;
    let { negativeCountryFilter } = this.state;
    if (this.props.group) {
      negativeCountryFilter = ['!=', ['get', 'group_id'], this.props.group];
    } else if (geojson && negativeCountryFilter[2] === '') {
      negativeCountryFilter = ['!=', ['get', 'country'], geojson.features[0].properties.country];
    }

    const areasFilter = [
      ...this.state.areaDefaultFilter,
      this.state.areaCountryFilter,
      areaConditionFilter,
    ];
    const areasNegativeFilter = [
      ...this.state.areaDefaultFilter,
      negativeCountryFilter,
      areaConditionFilter,
    ];
    const countriesNegativeFilter = [...this.state.areaDefaultFilter, negativeCountryFilter];

    if (type !== 'vector' && !this.props.group) {
      // ...change layer source ids to geojson source
      Object.values(layerData).forEach(d => {
        d.source = 'geojson-source';
        delete d['source-layer'];
      });
    } else {
      // only filter by time if it's not a single analysis
      let period = 'current';
      if (this.props.activePeriod === 'A') period = 'projected2';
      if (this.props.activePeriod === 'P') period = 'projected';

      areasFilter.push(['==', ['get', 'group_id'], this.props.group]);
      areaPhaseFilter[1][1] = `${period}_overall_phase`;
      layerData.areasGroup.id = 'areas';
      const fillColor = areasGroup.paint['fill-color'].map(exp => {
        let f = exp;
        if (_.isArray(f) && f[2] && _.isArray(f[2])) {
          f = JSON.parse(JSON.stringify(exp));
          f[2][1][1] = `${period}_overall_phase`;
        }
        return f;
      });
      layerData.areasGroup.paint = {
        ...layerData.areasGroup.paint,
        'fill-color': [...fillColor],
      };
      layerData.areasGroupFamine.paint['fill-pattern'][1][1] = `${period}_overall_phase`;
      layerData.areasBorder['source-layer'] = 'groups';
      layerData.areasHighlight['source-layer'] = 'groups';
      layerData.urbanAreas['source-layer'] = 'groups_points';
      layerData.urbanAreasCenter['source-layer'] = 'groups_points';
      layerData.idp['source-layer'] = 'groups_points';
      layerData.idpHover['source-layer'] = 'groups_points';
      layerData.hhg['source-layer'] = 'groups_points';
      layerData.hhgHover['source-layer'] = 'groups_points';
      layerData.areasLabels['source-layer'] = 'groups';
    }
    areasFilter.push(areaPhaseFilter);
    areasNegativeFilter.push(areaPhaseFilter);

    if (type === 'labels') {
      return (
        <Layer beforeId="settlement-major-label" {...layerData.areasLabels} filter={areasFilter} />
      );
    }

    return (
      <React.Fragment>
        {type === 'vector' && (
          <React.Fragment>
            <Layer {...layerData.countriesMask} filter={countriesNegativeFilter} />
            <Layer
              beforeId="waterway-shadow"
              {...layerData.areasNegative}
              filter={areasNegativeFilter}
            />
          </React.Fragment>
        )}
        {type !== 'vector' && (
          <React.Fragment>
            {this.props.group ? (
              <>
                <Layer beforeId="waterway-shadow" {...layerData.areasGroup} filter={areasFilter} />
                <Layer
                  beforeId="waterway-shadow"
                  {...layerData.areasGroupFamine}
                  filter={areasFilter}
                />
              </>
            ) : (
              <>
                <Layer beforeId="waterway-shadow" {...layerData.areas} filter={areasFilter} />
                <Layer beforeId="waterway-shadow" {...layerData.areasFamine} filter={areasFilter} />
              </>
            )}
            <Layer beforeId="waterway-label" {...layerData.areasBorder} filter={areasFilter} />
            <Layer beforeId="waterway-label" {...layerData.areasHighlight} />
            <Layer
              beforeId="waterway-label"
              {...layerData.urbanAreas}
              filter={[
                'all',
                ['==', ['get', 'admin_type'], 'urb'],
                areaPhaseFilter,
                ...areasFilter.slice(1),
              ]}
            />
            <Layer
              beforeId="waterway-label"
              {...layerData.urbanAreasCenter}
              filter={[
                'all',
                ['==', ['get', 'admin_type'], 'urb'],
                areaPhaseFilter,
                ...areasFilter.slice(1),
              ]}
            />
            <Layer
              beforeId="waterway-label"
              {...layerData.idp}
              filter={[
                'all',
                ['==', ['get', 'admin_type'], 'idp'],
                areaPhaseFilter,
                ...areasFilter.slice(1),
              ]}
            />
            <Layer
              beforeId="waterway-label"
              {...layerData.idpHover}
              filter={[
                'all',
                ['==', ['get', 'admin_type'], 'idp'],
                areaPhaseFilter,
                ...areasFilter.slice(1),
              ]}
            />
            <Layer
              beforeId="waterway-label"
              {...layerData.hhg}
              filter={[
                'all',
                ['==', ['get', 'admin_type'], 'hhg'],
                areaPhaseFilter,
                ...areasFilter.slice(1),
              ]}
            />
            <Layer
              beforeId="waterway-label"
              {...layerData.rfg}
              filter={[
                'all',
                ['==', ['get', 'admin_type'], 'rfg'],
                areaPhaseFilter,
                ...areasFilter.slice(1),
              ]}
            />
            <Layer
              beforeId="waterway-label"
              {...layerData.rfgHover}
              filter={[
                'all',
                ['==', ['get', 'admin_type'], 'rfg'],
                areaPhaseFilter,
                ...areasFilter.slice(1),
              ]}
            />
            <Layer
              beforeId="waterway-label"
              {...layerData.hhgHover}
              filter={[
                'all',
                ['==', ['get', 'admin_type'], 'hhg'],
                areaPhaseFilter,
                ...areasFilter.slice(1),
              ]}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { tooltip, stickyPopup } = this.state;
    // is this a single analysis/is the data layer from geojson?
    const { geojson, insets, icons, group, showStickyPopups, draggable } = this.props;
    const interactiveLayerIds = ['areas', 'urbanAreas', 'idp', 'idpHover', 'hhg', 'hhgHover'];
    const getSources = () => (
      <React.Fragment>
        {/* single analysis map from geojson */}
        <React.Fragment>
          {group ? (
            <Source type="vector" id="ipc" tiles={[`${process.env.REACT_APP_TILE_URL}`]}>
              {this.getLayers()}
              {this.getLayers('vector')}
            </Source>
          ) : (
            <React.Fragment>
              {geojson && (
                <>
                  <Source type="geojson" data={geojson} id="geojson-source">
                    {this.getLayers()}
                  </Source>
                  <Source
                    type="geojson"
                    data={{
                      ...geojson,
                      features: geojson.features.map(feature => ({
                        ...feature,
                        geometry: centroid(feature.geometry).geometry,
                      })),
                    }}
                    id="geojson-labels-source"
                  >
                    {this.getLayers('labels')}
                  </Source>
                </>
              )}
              <Source type="vector" id="ipc" tiles={[`${process.env.REACT_APP_TILE_URL}`]}>
                {this.getLayers('vector')}
              </Source>
            </React.Fragment>
          )}
          {icons && !draggable && (
            <Source type="geojson" data={icons} id="geojson-icons">
              <Layer
                id="bags"
                source="geojson-icons"
                type="symbol"
                layout={bags.layout}
                filter={['all', ['!=', ['get', 'icon'], 'rfm'], this.state.areaCountryFilter]}
              />
              <Layer
                id="rfm"
                source="geojson-icons"
                type="symbol"
                layout={rfm.layout}
                filter={['all', ['==', ['get', 'icon'], 'rfm'], this.state.areaCountryFilter]}
              />
            </Source>
          )}
          {icons && draggable && (
            <>
              {_.sortBy(
                icons.features,
                i => -['bag', 'bag2', 'urb', 'idb', 'idp', 'rfm', 'rfg'].indexOf(i.properties.icon)
              ).map(f => {
                const {
                  geometry: { coordinates },
                  properties: {
                    id,
                    anl_id,
                    aar_id,
                    area,
                    period,
                    icon,
                    schema_country,
                    overall_phase,
                  },
                } = f;
                return (
                  <AtlasMarkerDraggable
                    key={`${id}-${icon}-${period}`}
                    id={id}
                    longitude={coordinates[0]}
                    latitude={coordinates[1]}
                    anl_id={anl_id}
                    area={aar_id}
                    phase={overall_phase}
                    period={period}
                    icon={icon}
                    name={area}
                    country={schema_country}
                  />
                );
              })}
            </>
          )}
        </React.Fragment>
      </React.Fragment>
    );
    return (
      <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
        <div
          style={{
            height: this.disclaimer.current ? 530 - this.disclaimer.current.clientHeight : '100%',
          }}
          key={`mainMap-${this.props.activePeriod}`}
        >
          <ReactMapGL
            key="mainMap"
            ref={this.map}
            {...this.state.viewport}
            minZoom={1}
            maxZoom={12}
            attributionControl={false}
            onHover={this.onHover}
            onClick={this.onClick}
            // onMouseDown={this.onMousedown}
            onMouseOut={this.removeTooltip}
            dragPan={this.state.allowPan}
            dragRotate={false}
            touchRotate={false}
            interactiveLayerIds={interactiveLayerIds}
            mapboxApiAccessToken="pk.eyJ1IjoidGdhYnJpZWxsZSIsImEiOiJjbTduOHpwejkwc2htMmxzNnd5MG52cDd1In0.3Tas9dCX-edLJFLEIfHU6w"
            mapStyle={`${process.env.PUBLIC_URL}/styles/${this.props.basemapStyle}`}
            onViewportChange={newViewport =>
              this.setState({
                viewport: { ...newViewport },
                bounds: this.map.current ? this.map.current.getMap().getBounds() : null,
              })
            }
            onLoad={() => {
              const map = this.map.current.getMap();
              if (this.props.initialBounds && this.props.initialBounds.length) {
                let { width, height } = this.state.viewport;
                width -= 40;
                height -= 40;
                const vp = new WebMercatorViewport({ width, height }).fitBounds(
                  this.props.initialBounds
                );
                const { longitude, latitude, zoom } = vp;
                this.setState({ viewport: { ...this.state.viewport, longitude, latitude, zoom } });
              }
              const bounds = map.getBounds();
              this.setState({
                originalStyle: { ...map.getStyle() },
                globalBounds: [
                  [bounds.getWest(), bounds.getSouth()],
                  [bounds.getEast(), bounds.getNorth()],
                ],
                areaLabelsFilter: ['==', ['get', 'country'], 'NO MATCHES'],
                bounds,
              });
            }}
          >
            {getSources()}
            {showStickyPopups &&
              Object.keys(stickyPopup).map(id => {
                const sticky = stickyPopup[id];
                return (
                  <>
                    <Marker
                      draggable
                      longitude={sticky.lng}
                      latitude={sticky.lat}
                      onDragStart={() => {
                        this.removeTooltip();
                        this.setState({ showTooltip: false });
                      }}
                      onDrag={e => {
                        const map = this.map.current.getMap();
                        const [lng, lat] = e.lngLat;
                        let { x, y } = map.project(sticky.origin);
                        x += sticky.left ? 100 : -100;
                        y += sticky.top ? 50 : -50;
                        const threshold = map.unproject([x, y]);
                        const top = threshold.lat < lat;
                        const left = threshold.lng > lng;
                        stickyPopup[id] = { ...sticky, lng, lat, top, left };
                        this.setState(stickyPopup);
                      }}
                      onDragEnd={() => this.setState({ showTooltip: true })}
                    >
                      <AtlasTooltip
                        key="mapTooltip"
                        persistent
                        top={sticky.top}
                        left={sticky.left}
                        feature={sticky.feature}
                        pos={{ x: 0, y: 0 }}
                        onClose={() => {
                          this.setState({
                            stickyPopup: _.omit(this.state.stickyPopup, id),
                          });
                        }}
                      />
                    </Marker>
                  </>
                );
              })}
            {!!Object.keys(stickyPopup).length && showStickyPopups && (
              <Source
                type="geojson"
                data={multiLineString(
                  Object.keys(stickyPopup).map(id => [
                    stickyPopup[id].origin,
                    [stickyPopup[id].lng, stickyPopup[id].lat],
                  ])
                )}
              >
                <Layer
                  id="popupLeaderBack"
                  type="line"
                  paint={{ 'line-width': 10, 'line-color': '#666666', 'line-blur': 8 }}
                  layout={{ 'line-cap': 'round' }}
                ></Layer>
                <Layer
                  id="popupLeader"
                  type="line"
                  paint={{ 'line-width': 2, 'line-color': '#ffffff' }}
                  layout={{ 'line-cap': 'round' }}
                ></Layer>
              </Source>
            )}
            <div key="mainMapControls" style={{ position: 'absolute', left: 10, top: 10 }}>
              <NavigationControl showCompass={false} />
            </div>
          </ReactMapGL>
          <div key="disclaimer" className="map-bottom-disclaimer" ref={this.disclaimer}>
            {this.disclaimerText}
          </div>
          {insets && ( // insets is a boolean but perhaps could instead specify *which* insets
            <React.Fragment key="mapInsets">
              {/* <AtlasInset
                filter={[...this.state.areaDefaultFilter]}
                handle="se"
                position="nw"
                saveMapRef={this.props.saveMapRef}
                activePeriod={this.props.activePeriod}
                deleteMapRef={this.props.deleteMapRef}
                bounds={this.state.bounds}
                layers={getSources()}
                swapZoom
              /> */}
              <AtlasInset
                filter={[...this.state.areaDefaultFilter]}
                handle="sw"
                position="ne"
                saveMapRef={this.props.saveMapRef}
                activePeriod={this.props.activePeriod}
                deleteMapRef={this.props.deleteMapRef}
                bounds={this.state.bounds}
                layers={getSources()}
              />
              {/* <AtlasInset
                filter={[...this.state.areaDefaultFilter]}
                handle="nw"
                position="se"
                saveMapRef={this.props.saveMapRef}
                activePeriod={this.props.activePeriod}
                deleteMapRef={this.props.deleteMapRef}
                bounds={this.state.bounds}
                layers={getSources()}
                swapZoom
              /> */}
              <AtlasInset
                filter={[...this.state.areaDefaultFilter]}
                handle="ne"
                position="sw"
                saveMapRef={this.props.saveMapRef}
                activePeriod={this.props.activePeriod}
                deleteMapRef={this.props.deleteMapRef}
                bounds={this.state.bounds}
                layers={getSources()}
              />
            </React.Fragment>
          )}
        </div>
        {tooltip}
      </ReactResizeDetector>
    );
  }
}
Atlas.propTypes = {
  styleOverrides: PropTypes.object,
  activePhases: PropTypes.arrayOf(PropTypes.number),
  saveMapRef: PropTypes.func.isRequired,
  deleteMapRef: PropTypes.func.isRequired,
  setHighlightedFeature: PropTypes.func.isRequired,
  highlightedPhases: PropTypes.arrayOf(PropTypes.number),
  basemapStyle: PropTypes.string,
  geojson: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.array,
  }),
  icons: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.array,
  }),
  insets: PropTypes.bool,
  showStickyPopups: PropTypes.bool,
  analysisId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highlightedFeature: PropTypes.object,
  highlightedAreas: PropTypes.arrayOf(PropTypes.string),
  activeCondition: PropTypes.string,
  activePeriod: PropTypes.string,
  clickOverride: PropTypes.func,
  compareBounds: PropTypes.arrayOf(PropTypes.array),
  draggable: PropTypes.bool,
  show_area_popups: PropTypes.bool,
};
Atlas.defaultProps = {
  basemapStyle: 'light.json',
  insets: true,
  showStickyPopups: false,
  highlightedFeature: null,
  highlightedAreas: null,
  activeCondition: 'A',
  clickOverride: null,
  compareBounds: null,
  draggable: false,
  show_area_popups: true,
};
export default Atlas;
